import { Resort } from "@maxxton/cms-mxts-api";
import { WidgetOptions } from "./";

export const RESORT_FACET_AGGREGATION = {
    name: "RESORT_FACET",
    field: "RESORT_ID",
    excludeFields: ["RESORT_ID"],
    type: "FACET",
    size: 1000,
};

export class ResortFacetUtil {
    static getSelectedResortNames = ({ resorts, selectedResortIds = [] }: { resorts: Resort[]; selectedResortIds: number[] }): string[] => {
        if (resorts?.length && selectedResortIds?.length) {
            const selectedResorts = resorts.filter((resort) => selectedResortIds.findIndex((id) => id === resort.resortId) !== -1);
            return selectedResorts.map((resort) => resort.name);
        }
        return [];
    };

    /**
     * Returns a list of resorts that need to be displayed, based on the widget settings.
     */
    static determineWhichResortsToDisplay = ({ allResorts, availableResortIds, widgetOptions }: ResortsDisplayParameters): Resort[] => {
        let displayedResorts: Resort[];
        const availableResorts = ResortFacetUtil.getAvailableResorts({ allResorts, availableResortIds });
        const unavailableResorts = ResortFacetUtil.getUnavailableResorts({ allResorts, availableResortIds });

        if (widgetOptions.greyOutUnavailableResorts) {
            if (widgetOptions.availableResortsFirst) {
                if (widgetOptions.sortResortsByPriority) {
                    displayedResorts = ResortFacetUtil.sortByPriority(availableResorts).concat(ResortFacetUtil.sortByPriority(unavailableResorts));
                } else {
                    displayedResorts = availableResorts.concat(unavailableResorts);
                }
            } else {
                displayedResorts = ResortFacetUtil.sortByPriority(allResorts);
            }
        } else {
            displayedResorts = widgetOptions.sortResortsByPriority ? ResortFacetUtil.sortByPriority(availableResorts) : availableResorts;
        }

        return displayedResorts;
    };

    // Sort priority from high to low in ascending order eg: 1,2,3....5
    static sortByPriority = (resorts: Resort[]): Resort[] => resorts.sort((a, b) => (a.resortPriority > b.resortPriority ? 1 : -1));

    static getAvailableResorts = ({ allResorts, availableResortIds }: { allResorts: Resort[]; availableResortIds: number[] }) =>
        allResorts.filter((resort: Resort) => availableResortIds.indexOf(resort.resortId) !== -1);

    static getUnavailableResorts = ({ allResorts, availableResortIds }: { allResorts: Resort[]; availableResortIds: number[] }) =>
        allResorts.filter((resort: Resort) => availableResortIds.indexOf(resort.resortId) === -1);
}

export interface ResortsDisplayParameters {
    allResorts: Resort[];
    availableResortIds: number[];
    widgetOptions: WidgetOptions;
}
