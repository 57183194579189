import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";

import { Aggregation, AvailabilityResult, Resort } from "@maxxton/cms-mxts-api";
import { Dropdown, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import LocalizedTitleAndLabel, { LocalizedTitleProps } from "../../../components/widgetTitleAndLabel/LocalizedLableTitle";
import { RESORT_FACET_AGGREGATION, ResortFacetUtil } from "./resortFacet.util";
import { Resort as ResortWithAddress, getMxtsEnv } from "../../mxts";
import { chunk, isEmpty } from "lodash";
import { dispatchEmptyAction, getHideWidgetClass, isClientLoggedIn, isEqual } from "../../../components/utils";
import { getAction, mergeAggregationsWithDynamicFilter } from "../accommodationKind/accommodationKindFacet.util";
import { getI18nLocaleString, wrapProps } from "../../../i18n";
import { hideTooltip, icons } from "../../../utils/generic.util";

import { ActionType } from "../../../redux/actions";
import { AvailabilityState } from "../../../redux/reducers/availability.types";
import { BoxModelUtil } from "../../../utils/boxmodel.util";
import { Dispatch } from "redux";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { DynamicWidgetBaseProps } from "../dynamicWidget.types";
import { FacetDisplayType } from "../../../utils/facet.util";
import { FilterChangeAction } from "../../../redux/actions/dynamicFilterAction.types";
import { OverviewPanelChildOptions } from "../overview-panel";
import { State } from "../../../redux";
import { WidgetOptions } from "./";
import { connect } from "react-redux";
import { dynamicFilterType } from "../../../redux/reducers/dynamicFilter.enum";
import { fetchAllResorts } from "../../../utils/resort.util";
import { getLabelOptions } from "../../../components/widgetTitleAndLabel/localizedLableTitle.util";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import namespaceList from "../../../i18n/namespaceList";
import { warmupState } from "../../../utils/cacheWarmup.util";

interface ResortFacetProps extends ResortFacetBaseProps, ResortFacetStoreProps, ResortFacetDispatchProps {}

interface ResortFacetBaseProps extends DynamicWidgetBaseProps<WidgetOptions> {
    childOptions?: OverviewPanelChildOptions;
}

interface ResortFacetState {
    allBookableResorts: Resort[];
    allResortsWithAddress: ResortWithAddress[];
    disableWidget: boolean;
    overviewResorts: Resort[];
    removedResortIds: number[];
    resortDropdownOpen?: boolean;
    resortsAreCleared: boolean;
    selectedResortIds: number[];
    resortModalPopup: boolean;
}

interface ResortFacetStoreProps {
    dynamicFilter: DynamicFilter;
    availabilityIsFetching: boolean;
    availableResortIds: number[];
    availabilityState: AvailabilityState;
}

interface ResortFacetDispatchProps {
    dispatchAction: Dispatch<FilterChangeAction>;
}

export class ResortFacetWidget extends React.Component<ResortFacetProps, ResortFacetState> {
    public static async warmupCache(props: ResortFacetProps): Promise<ResortFacetState> {
        return warmupState(props, ResortFacetWidget.defaultState(props), async (stateHandler) => {
            const { options, context, availabilityState } = props;
            const resortIds = availabilityState.availabilityResult?.response.resorts;
            const allBookableResorts = await ResortFacetWidget.getAllBookableResorts(props, resortIds);

            if (options.showResortPlaceName) {
                const env = await getMxtsEnv(context, context.currentLocale.code);
                const allResortsWithAddress = await fetchAllResorts({ env, includeAddress: true }, context.mxtsApi);
                stateHandler.setState({
                    allResortsWithAddress,
                });
            }

            stateHandler.setState({
                disableWidget: !isClientLoggedIn(),
                allBookableResorts,
            });
        });
    }

    public static async initDefaultFilter(props: ResortFacetProps): Promise<void> {
        await ResortFacetWidget.populateDynamicFilterWithPreSelectedFilters(props);
    }

    private static async populateDynamicFilterWithPreSelectedFilters(props: ResortFacetProps): Promise<DynamicFilter> {
        const { dynamicFilter, dispatchAction } = props;
        const filterType = dynamicFilterType.addPreselectedFilters;
        const updatedDynamicFilter = mergeAggregationsWithDynamicFilter(dynamicFilter, [RESORT_FACET_AGGREGATION] as Aggregation[]);
        const action = getAction(filterType, updatedDynamicFilter);
        dispatchAction(action);
        return updatedDynamicFilter;
    }

    private static defaultState(props: ResortFacetProps): ResortFacetState {
        return {
            allBookableResorts: [],
            allResortsWithAddress: [],
            disableWidget: true,
            overviewResorts: [],
            removedResortIds: [],
            resortDropdownOpen: false,
            resortsAreCleared: false,
            selectedResortIds: [],
            resortModalPopup: false,
        };
    }

    constructor(props: ResortFacetProps) {
        super(props);
        this.state = {
            ...ResortFacetWidget.defaultState(props),
        };
    }

    public async componentDidMount() {
        const { dispatchAction, options, context, availabilityState } = this.props;
        dispatchEmptyAction(dispatchAction);

        const resortIds = availabilityState.availabilityResult?.response?.resorts;
        const allBookableResorts = await ResortFacetWidget.getAllBookableResorts(this.props, resortIds);

        if (options.showResortPlaceName) {
            const env = await getMxtsEnv(context, context.currentLocale.code);
            const allResortsWithAddress = await fetchAllResorts({ env, includeAddress: true }, context.mxtsApi);
            this.setState({
                allResortsWithAddress,
            });
        }

        this.setState({
            disableWidget: !isClientLoggedIn(),
            allBookableResorts,
        });
    }

    public componentDidUpdate(prevProps: ResortFacetProps, prevState: ResortFacetState) {
        const { availabilityIsFetching, availableResortIds, dynamicFilter } = this.props;

        const allBookableResortsChanged = !isEqual(prevState.allBookableResorts, this.state.allBookableResorts);
        const availableResortIdsChanged = !availabilityIsFetching && !isEqual(prevProps.availableResortIds, availableResortIds);
        const filterContainsNewResorts = dynamicFilter.resortids && !isEqual(prevState.selectedResortIds, dynamicFilter.resortids);
        const clearResorts = !this.state.resortsAreCleared && !!prevState.selectedResortIds.length && !dynamicFilter.resortids;

        if (allBookableResortsChanged || availableResortIdsChanged) {
            this.updateResorts(this.props);
        }

        if (allBookableResortsChanged || filterContainsNewResorts || clearResorts) {
            this.updateSelectedResorts(this.props, clearResorts);
        }
    }

    public UNSAFE_componentWillReceiveProps(nextProps: Readonly<ResortFacetProps>): void {
        const {
            availabilityState: { availabilityResult },
        } = this.props;
        if (!isEqual(availabilityResult?.response?.resorts, nextProps?.availabilityState.availabilityResult?.response?.resorts)) {
            const resortIds = nextProps.availabilityState.availabilityResult?.response.resorts;
            ResortFacetWidget.getAllBookableResorts(nextProps, resortIds).then((allBookableResorts) => {
                this.setState({ allBookableResorts });
            });
        }
    }

    public render(): JSX.Element | null {
        const {
            context: { currentLocale, site },
            options,
        } = this.props;
        const localizedWidgetTitle: string = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedWidgetTitle || [], keys: ["widgetTitleText"] })?.widgetTitleText || "";
        const titleOptions = {
            localizedTitle: localizedWidgetTitle,
            enableWidgetTitle: options.enableWidgetTitle,
            useTitleHeadings: options.useTitleHeadings,
            styleWidgetTitle: options.styleWidgetTitle,
            className: classNames("widget-heading", `${options.textTitleColor?.includes("theme") ? `color-${options.textTitleColor}` : ""}`),
            style: options.textTitleColor?.includes("rgba") ? options.textTitleColor : "",
        };
        const hideWidget = getHideWidgetClass(this.props.options, this.state.disableWidget);
        if (hideWidget === null) {
            return null;
        }
        if (this.props.options.displayType === FacetDisplayType.GRID) {
            return this.renderGrid(hideWidget, titleOptions);
        }
        if (this.props.options.displayType === FacetDisplayType.LIST) {
            return this.renderList(hideWidget, titleOptions);
        }
        if (this.props.options.displayType === FacetDisplayType.MODAL_POPUP) {
            return this.renderModalPopup(hideWidget, titleOptions);
        }
        return this.renderPopup(hideWidget, titleOptions);
    }

    private renderGrid(hideWidget: string, titleOptions: LocalizedTitleProps) {
        const { allBookableResorts, allResortsWithAddress, selectedResortIds } = this.state;
        const { availableResortIds, options } = this.props;
        let displayedResorts: ResortWithAddress[] = ResortFacetUtil.determineWhichResortsToDisplay({ allResorts: allBookableResorts, availableResortIds, widgetOptions: options });
        const displayedResortIds: number[] = displayedResorts.map((resort: ResortWithAddress) => resort.resortId);

        if (options.showResortPlaceName) {
            displayedResorts = allResortsWithAddress.filter((resort: ResortWithAddress) => displayedResortIds.includes(resort.resortId));
        }

        if (options.sortResortsByPriority) {
            displayedResorts = ResortFacetUtil.sortByPriority(displayedResorts);
        }

        return (
            <React.Fragment>
                <LocalizedTitleAndLabel {...titleOptions} />
                <div className={classNames("resort-facet-grid-layout", "grid-layout", `grid-layout--${options.numberOfColumnsInGridView}`, `${hideWidget}`)}>
                    {displayedResorts.map((resort: ResortWithAddress, count: number) => (
                        <div
                            key={count}
                            className={classNames("resort-facet-grid-layout__resort", {
                                "resort-facet-grid-layout__resort--selected": selectedResortIds.includes(resort.resortId),
                                "resort-facet-grid-layout__resort--unavailable": !availableResortIds.includes(resort.resortId),
                            })}
                            onClick={() => (options.useSingleResortSelection ? this.handleSingleResortSelect(resort.resortId) : this.handleGridResortClick(resort.resortId))}
                        >
                            <span className={classNames("resort__name")}>{resort.name}</span>
                            {options.showResortPlaceName && resort.address?.city && <span className={classNames("resort__address")}>{resort.address?.city}</span>}
                        </div>
                    ))}
                </div>
            </React.Fragment>
        );
    }

    private renderList(hideWidget: string, titleOptions: LocalizedTitleProps) {
        const { allBookableResorts, selectedResortIds, overviewResorts } = this.state;
        const {
            availableResortIds,
            childOptions,
            options: { useSingleResortSelection },
        } = this.props;
        const availableResorts = ResortFacetUtil.getAvailableResorts({ allResorts: allBookableResorts, availableResortIds });

        return (
            <React.Fragment>
                <LocalizedTitleAndLabel {...titleOptions} />
                <div className={`dynamic-resort-facet ${hideWidget}`}>
                    {!childOptions &&
                        availableResorts.map((resort, count) =>
                            resort ? (
                                <div key={count} className="search-window__item">
                                    {useSingleResortSelection ? (
                                        <div
                                            className={`resort-item ${selectedResortIds.indexOf(resort.resortId) > -1 ? "selectedValue" : ""}`}
                                            onClick={() => this.handleSingleResortSelect(resort.resortId)}
                                        >
                                            {resort.name}
                                        </div>
                                    ) : (
                                        <React.Fragment>
                                            <input
                                                onChange={(e) => this.handleResort(e, resort)}
                                                type="checkbox"
                                                name={resort.name}
                                                checked={selectedResortIds.indexOf(resort.resortId) > -1}
                                                className="search-window__item-input"
                                            />
                                            <label className="resort-name search-window__item-label" onClick={(e) => this.handleResort(e, resort)}>
                                                {resort.name}
                                            </label>
                                        </React.Fragment>
                                    )}
                                </div>
                            ) : null
                        )}
                    {childOptions &&
                        overviewResorts &&
                        overviewResorts.map((resort, count) =>
                            resort ? (
                                <div key={count} className="search-window__item">
                                    {useSingleResortSelection ? (
                                        <div className="resort-list">
                                            <div
                                                className={`search-window__item ${selectedResortIds.indexOf(resort.resortId) > -1 ? "selectedValue" : ""}`}
                                                onClick={() => this.handleSingleResortSelect(resort.resortId)}
                                            >
                                                {resort.name}
                                            </div>
                                        </div>
                                    ) : (
                                        <React.Fragment>
                                            {childOptions.isEditable && (
                                                <input
                                                    onChange={(e) => this.handleResort(e, resort)}
                                                    type="checkbox"
                                                    name={resort.name}
                                                    checked={selectedResortIds.indexOf(resort.resortId) > -1}
                                                    className="search-window__item-input"
                                                />
                                            )}
                                            <label className="resort-name search-window__item-label">{resort.name}</label>
                                        </React.Fragment>
                                    )}
                                </div>
                            ) : null
                        )}
                </div>
            </React.Fragment>
        );
    }

    private renderPopup(hideWidget: string, titleOptions: LocalizedTitleProps) {
        const { allBookableResorts, selectedResortIds } = this.state;
        const {
            context: { currentLocale, site },
            options,
            className,
        } = this.props;
        const { highlightColor, highlightInput, closeIcon, enableModalLabel, localizedModalLabel } = options;
        const selectedResortNames = ResortFacetUtil.getSelectedResortNames({ resorts: allBookableResorts, selectedResortIds });
        const modalLabel = enableModalLabel && localizedModalLabel && getLocalizedContent({ site, currentLocale, localizedContent: localizedModalLabel })?.labelText;
        const labelOptions = getLabelOptions(options, site, currentLocale);
        return (
            <React.Fragment>
                <LocalizedTitleAndLabel {...titleOptions} />
                <div
                    // eslint-disable-next-line max-len
                    className={`dynamic-resort ${className} ${options.displayBtn ? "display-filter-btn" : ""} search-filter-box ${options.addIcon ? "add-icon" : ""} ${
                        options.iconColor && options.iconColor.indexOf("theme") > -1
                            ? `color-${options.iconColor}`
                            : options.iconColor && options.iconColor.indexOf("rgba") === -1
                            ? "icon-" + options.iconColor
                            : ""
                    } ${options.addIcon && options.iconOutside ? "icon-outside" : "icon-inside"} ${options.addIcon && options.iconRight ? "move-icon-right" : "move-icon-left"} ${
                        options.showArrow ? "show-arrows" : ""
                    } ${hideWidget}`}
                    style={{ color: options.addIcon && options.iconColor && options.iconColor.indexOf("rgba") > -1 ? options.iconColor : undefined }}
                >
                    <Dropdown
                        nav
                        className={`resort-inner search-filter-box--inner ${isEmpty(selectedResortIds) && highlightInput && !highlightColor?.includes("rgba") ? `box-shadow-${highlightColor}` : ""}`}
                        isOpen={this.state.resortDropdownOpen}
                        toggle={this.toggleResortDropdown}
                        {...{ style: isEmpty(selectedResortIds) && highlightInput && highlightColor?.includes("rgba") ? BoxModelUtil.highlightStyling(highlightColor) : {} }}
                    >
                        <DropdownToggle
                            {...{ style: { color: options.addIcon && options.iconColor && options.iconColor.indexOf("rgba") > -1 ? options.iconColor : undefined } }}
                            nav
                            className={`search-filter-box--inner__label ${selectedResortNames.length > 0 ? "active" : ""}`}
                        >
                            <span className="filter-data-wrap">
                                <LocalizedTitleAndLabel {...labelOptions} />
                                <span className="search-filter-box--inner__label-selected">
                                    {selectedResortNames.length > 0
                                        ? `${selectedResortNames[0]}${selectedResortNames.length > 1 ? icons.PLUS : ""}${selectedResortNames.length - 1 || ""}`
                                        : getI18nLocaleString(namespaceList.dynamicResort, "allResorts", currentLocale, site)}
                                </span>
                            </span>
                        </DropdownToggle>
                        <DropdownMenu className="resort-dropdown search-filter-box--inner__dropdown">
                            {modalLabel && <label className="label-inside-popup">{modalLabel}</label>}
                            {closeIcon && (
                                <span className="popup-close" onClick={this.toggleResortDropdown}>
                                    <FontAwesome name="close" />
                                </span>
                            )}
                            {this.getInnerDropdown()}
                            {options.showApplyClear ? (
                                <div className="filter-action-buttons">
                                    <a className="action-button__clear" role="button" onClick={this.clearResults}>
                                        {getI18nLocaleString(namespaceList.admin, "clear", currentLocale, site)}
                                    </a>
                                    <a className="action-button__apply" role="button" onClick={this.applyResults}>
                                        {getI18nLocaleString(namespaceList.admin, "apply", currentLocale, site)}
                                    </a>
                                </div>
                            ) : null}
                        </DropdownMenu>
                        <div className={`${this.state.resortDropdownOpen ? "backdrop-popup" : ""}`} onClick={this.state.resortDropdownOpen ? this.toggleResortDropdown : undefined}></div>
                    </Dropdown>
                </div>
            </React.Fragment>
        );
    }

    private renderModalPopup(hideWidget: string, titleOptions: LocalizedTitleProps) {
        const { allBookableResorts, selectedResortIds } = this.state;
        const {
            context: { currentLocale, site },
            options,
        } = this.props;
        const { highlightColor, highlightInput } = options;
        const selectedResortNames = ResortFacetUtil.getSelectedResortNames({ resorts: allBookableResorts, selectedResortIds });
        const labelOptions = getLabelOptions(options, site, currentLocale);
        return (
            <React.Fragment>
                <LocalizedTitleAndLabel {...titleOptions} />
                <div
                    className={`dynamic-resort ${options.displayBtn ? "display-filter-btn" : ""} search-filter-box ${options.addIcon ? "add-icon" : ""} ${
                        options.iconColor?.includes("theme") ? `color-${options.iconColor}` : options.iconColor && options.iconColor.indexOf("rgba") === -1 ? "icon-" + options.iconColor : ""
                    } ${options.addIcon && options.iconOutside ? "icon-outside" : "icon-inside"} ${options.addIcon && options.iconRight ? "move-icon-right" : "move-icon-left"} ${
                        options.showArrow ? "show-arrows" : ""
                    } ${hideWidget}`}
                    style={{ color: options.addIcon && options.iconColor?.includes("rgba") ? options.iconColor : undefined }}
                >
                    <div
                        className={`resort-inner ${isEmpty(selectedResortIds) && highlightInput && !highlightColor?.includes("rgba") ? `box-shadow-${highlightColor}` : ""}`}
                        {...{ style: isEmpty(selectedResortIds) && highlightColor?.includes("rgba") ? BoxModelUtil.highlightStyling(highlightColor) : {} }}
                        onClick={this.handleResortInfoModal}
                    >
                        <a className="search-filter-box--inner__label resort-label">
                            <span className="filter-data-wrap">
                                <LocalizedTitleAndLabel {...labelOptions} />
                                <span className="search-filter-box--inner__label-selected">
                                    {selectedResortNames.length > 0
                                        ? `${selectedResortNames[0]}${selectedResortNames.length > 1 ? icons.PLUS : ""}${selectedResortNames.length - 1 || ""}`
                                        : getI18nLocaleString(namespaceList.dynamicResort, "allResorts", currentLocale, site)}
                                </span>
                            </span>
                            {this.resortModal()}
                        </a>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private getInnerDropdown = (): JSX.Element => {
        const {
            availableResortIds,
            childOptions,
            options: { greyOutUnavailableResorts, useSingleResortSelection },
            context: { currentLocale, site },
        } = this.props;
        const { allBookableResorts, selectedResortIds } = this.state;

        const availableResorts = ResortFacetUtil.getAvailableResorts({ allResorts: allBookableResorts, availableResortIds });
        const displayedResorts: Resort[] = ResortFacetUtil.determineWhichResortsToDisplay({ allResorts: allBookableResorts, availableResortIds, widgetOptions: this.props.options });

        const inputFieldShouldRender = !childOptions || (childOptions && childOptions.isEditable);

        return (
            <div className="dropdown-inner">
                {displayedResorts && (
                    <React.Fragment>
                        {useSingleResortSelection && (
                            <div className="search-window__item">
                                <div key="allResorts" className="resort-item" onClick={() => this.handleSingleResortSelect(null)}>
                                    {getI18nLocaleString(namespaceList.widgetSearchfacet, "allResorts", currentLocale, site)}
                                </div>
                            </div>
                        )}
                        {displayedResorts.map((resort, count) =>
                            resort ? (
                                <div
                                    key={count}
                                    className={classNames("search-window__item", {
                                        "label-with-checkbox__greyed-out":
                                            greyOutUnavailableResorts && !useSingleResortSelection && availableResorts.findIndex((someResort) => someResort.resortId === resort.resortId) === -1,
                                    })}
                                >
                                    {useSingleResortSelection ? (
                                        <div
                                            className={`resort-item ${selectedResortIds.indexOf(resort.resortId) > -1 ? "selectedValue" : ""}`}
                                            onClick={() => this.handleSingleResortSelect(resort.resortId)}
                                        >
                                            {resort.name}
                                        </div>
                                    ) : (
                                        <React.Fragment>
                                            {inputFieldShouldRender && (
                                                <input
                                                    id={`resort-${count}`}
                                                    onChange={(e) => this.handleResort(e, resort)}
                                                    type="checkbox"
                                                    name={resort.name}
                                                    checked={selectedResortIds.indexOf(resort.resortId) > -1}
                                                    disabled={
                                                        selectedResortIds.indexOf(resort.resortId) === -1 && availableResorts.findIndex((someResort) => someResort.resortId === resort.resortId) === -1
                                                    }
                                                    className="search-window__item-input"
                                                />
                                            )}
                                            <label className="reosrt-name search-window__item-label" htmlFor={`resort-${count}`}>
                                                {resort.name}
                                            </label>
                                        </React.Fragment>
                                    )}
                                </div>
                            ) : null
                        )}
                    </React.Fragment>
                )}
            </div>
        );
    };

    private toggleResortDropdown = () => {
        this.setState((state) => ({
            ...state,
            resortDropdownOpen: !state.resortDropdownOpen,
        }));
        hideTooltip();
    };

    private applyResults = () => {
        const { selectedResortIds, removedResortIds } = this.state;
        const action: FilterChangeAction = {
            type: ActionType.FilterChange,
            filter: dynamicFilterType.addresort,
            payload: { resortids: selectedResortIds, removedResortIds },
        };
        this.props.dispatchAction(action);
        this.setState({ removedResortIds: [] }, () => {
            this.toggleResortDropdown();
        });
    };

    private clearResults = () => {
        const { selectedResortIds } = this.state;
        const action: FilterChangeAction = {
            type: ActionType.FilterChange,
            filter: dynamicFilterType.removeresort,
            payload: { resortids: selectedResortIds },
        };
        this.props.dispatchAction(action);
        this.setState({ removedResortIds: [] }, () => {
            this.toggleResortDropdown();
        });
    };

    private updateSelectedResorts = (props: ResortFacetProps, clearResorts = false) => {
        const {
            dynamicFilter: { resortids: selectedResortIds },
        } = props;

        if (clearResorts) {
            this.setState({ resortsAreCleared: true, selectedResortIds: [] });
        } else {
            this.setState({
                resortsAreCleared: false,
                selectedResortIds: selectedResortIds || this.state.selectedResortIds,
            });
        }
    };

    private updateResorts = (props: ResortFacetProps): void => {
        const { allBookableResorts } = this.state;
        const availableResorts = ResortFacetUtil.getAvailableResorts({ allResorts: allBookableResorts, availableResortIds: props.availableResortIds });

        this.setState({
            overviewResorts: availableResorts,
        });
    };

    private handleResort = (event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLLabelElement, MouseEvent>, resort: Resort) => {
        const { options } = this.props;
        const { removedResortIds } = this.state;
        const targetIsChecked = (event.target as HTMLInputElement).checked;

        const selectedResortIds = targetIsChecked
            ? this.state.selectedResortIds.concat((resort as Resort).resortId)
            : this.state.selectedResortIds.filter((resortId) => resortId !== (resort as Resort).resortId);

        if (!targetIsChecked) {
            removedResortIds.push(resort.resortId);
        }
        this.setState({ selectedResortIds, removedResortIds });
        if (!options.showApplyClear) {
            this.dispatchResortChange(resort.resortId, targetIsChecked, selectedResortIds);
        }
    };

    private handleSingleResortSelect = (resortId: number | null) => {
        let selectedResortIds = [...this.state.selectedResortIds];
        if (!resortId) {
            selectedResortIds = [];
        } else if (selectedResortIds.indexOf(resortId) > -1) {
            selectedResortIds = selectedResortIds.filter((selectedResortId) => selectedResortId !== resortId);
        } else {
            selectedResortIds[0] = resortId;
        }

        this.props.dispatchAction({
            type: ActionType.FilterChange,
            filter: dynamicFilterType.updateresorts,
            payload: { resortids: selectedResortIds },
        });
        this.setState({ selectedResortIds });
    };

    private handleGridResortClick = (resortId: number): void => {
        // If the resort is currently selected in the state, it should be removed
        // If the resort is not yet selected, it should be added
        const addResort = !this.state.selectedResortIds.includes(resortId);

        const updatedSelectedResortIds = addResort ? this.state.selectedResortIds.concat(resortId) : this.state.selectedResortIds.filter((selectedId) => selectedId !== resortId);

        this.setState({
            selectedResortIds: updatedSelectedResortIds,
            removedResortIds: !addResort ? this.state.removedResortIds.concat(resortId) : this.state.removedResortIds,
        });

        if (!this.props.options.showApplyClear) {
            this.dispatchResortChange(resortId, addResort, updatedSelectedResortIds);
        }
    };

    private dispatchResortChange = (resortId: number, addResort: boolean, selectedResortIds: number[]): void => {
        if (addResort) {
            this.props.dispatchAction({
                type: ActionType.FilterChange,
                filter: dynamicFilterType.addresort,
                payload: { resortids: selectedResortIds },
            });
        } else {
            this.props.dispatchAction({
                type: ActionType.FilterChange,
                filter: dynamicFilterType.removeresort,
                payload: { resortids: [resortId] },
            });
        }
    };

    private static async getAllBookableResorts(props: ResortFacetProps, resortIds?: number[]): Promise<Resort[]> {
        const { context, availabilityState } = props;
        if (!availabilityState?.availabilityResult?.response) {
            return [];
        }

        const env = await getMxtsEnv(context, context.currentLocale.code);

        const idChunks: number[][] = chunk(resortIds, 125);
        const allObtainedChunks = await Promise.all(idChunks.map((ids) => context.mxtsApi.resorts(env, { resortIds: ids, size: ids.length })));
        return allObtainedChunks.map((pagedResult) => pagedResult.content).flat();
    }

    private handleResortInfoModal = () => {
        this.setState({ resortModalPopup: !this.state.resortModalPopup });
    };

    private resortModal = () => {
        const {
            context: { currentLocale, site },
            options,
        } = this.props;
        const { resortModalPopup } = this.state;
        return (
            <Modal isOpen={resortModalPopup} toggle={this.handleResortInfoModal} className={"resort-on-modal modal-lg"}>
                <ModalHeader tag="h4" toggle={this.handleResortInfoModal} className="no-background">
                    {getI18nLocaleString(namespaceList.dynamicResort, "resorts", currentLocale, site)}
                </ModalHeader>
                <ModalBody>
                    <div className="search-filter-box">
                        <div className="resort-inner">{this.getInnerDropdown()}</div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    {options.showApplyClear ? (
                        <div className="filter-action-buttons">
                            <a className="action-button__clear" role="button" onClick={this.clearResults}>
                                {getI18nLocaleString(namespaceList.admin, "clear", currentLocale, site)}
                            </a>
                            <a className="action-button__apply" role="button" onClick={this.applyResults}>
                                {getI18nLocaleString(namespaceList.admin, "apply", currentLocale, site)}
                            </a>
                        </div>
                    ) : null}
                </ModalFooter>
            </Modal>
        );
    };
}

function mapStateToProps(state: State): ResortFacetStoreProps {
    const availabilityResult: AvailabilityResult | undefined = state.availabilityState.availabilityResult;
    const availabilityIsFetching: boolean = state.availabilityState.fetching || false;

    return {
        dynamicFilter: state.dynamicFilter,
        availableResortIds: availabilityResult && availabilityResult.response.resorts ? availabilityResult.response.resorts : [],
        availabilityIsFetching,
        availabilityState: state.availabilityState,
    };
}

function mapDispatchToProps(dispatch: Dispatch<FilterChangeAction>): ResortFacetDispatchProps {
    return { dispatchAction: dispatch };
}

// eslint-disable-next-line max-len
const ResortFacet = connect<ResortFacetStoreProps, ResortFacetDispatchProps>(mapStateToProps, mapDispatchToProps)(ResortFacetWidget);

export const DynamicResortFacet = wrapProps<ResortFacetBaseProps>(ResortFacet);
