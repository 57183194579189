import * as React from "react";

import { DynamicResortFacet, ResortFacetWidget } from "./ResortFacet";
import { FacetDisplayType, FacetOptions, getFacetDisplayTypeOptions } from "../../../utils/facet.util";
import { FormSpec, multiSelectStylePicker } from "../../../form-specs";
import {
    LocalizedLabelOptions,
    LocalizedTitleOptions,
    getWidgetLabelOptions,
    getWidgetTitleOptions,
    labelStylingOptions,
    titleStylingOptions,
} from "../../../components/widgetTitleAndLabel/localizedLableTitle.util";

import { ModalLabel } from "../subject/Subject.types";
import { PageWidgetSpec } from "../../";
import { WidgetGroup } from "../../widget.enum";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { getI18nLocaleObject } from "../../../i18n";
import { getModalLabelOptions } from "../../../components/utils";
import { isServerSide } from "../../../utils/generic.util";
import namespaceList from "../../../i18n/namespaceList";

export interface WidgetOptions extends FacetOptions, LocalizedTitleOptions, LocalizedLabelOptions {
    addIcon: boolean;
    arrowColor: string;
    availableResortsFirst: boolean;
    displayBtn: boolean;
    greyOutUnavailableResorts: boolean;
    highlightColor: string;
    highlightInput: boolean;
    iconColor: string;
    iconOutside: boolean;
    iconRight: boolean;
    showApplyClear: boolean;
    showArrow: boolean;
    sortResortsByPriority: boolean;
    showResortPlaceName: boolean;
    styleIds: string[];
    textTitleColor?: string;
    textLabelColor?: string;
    closeIcon?: boolean;
    enableModalLabel?: boolean;
    localizedModalLabel?: ModalLabel[];
    className?: string;
    useSingleResortSelection: boolean;
}

const TARGETS = ["resorts"];
const isModalPopup = (displayType: FacetDisplayType): boolean => {
    const modalTypes = [FacetDisplayType.POPUP, FacetDisplayType.MODAL_POPUP];
    return modalTypes.includes(displayType);
};

export const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "dynamic-resort-widget",
    name: getI18nLocaleObject(namespaceList.dynamicResort, "resort"),
    pluralName: getI18nLocaleObject(namespaceList.dynamicResort, "resorts"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.widgetAgenda, "general"),
                    properties: [
                        [
                            ...getFacetDisplayTypeOptions(),
                            {
                                variable: "showResortPlaceName",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "showResortPlaceName"),
                                type: "checkbox",
                                default: false,
                                visible: (options: WidgetOptions) => options.displayType === FacetDisplayType.GRID,
                            },
                            {
                                variable: "greyOutUnavailableResorts",
                                label: getI18nLocaleObject(namespaceList.dynamicResort, "greyOutUnavailableResorts"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) =>
                                    options.displayType === FacetDisplayType.POPUP || options.displayType === FacetDisplayType.MODAL_POPUP || options.displayType === FacetDisplayType.GRID,
                            },
                            {
                                variable: "availableResortsFirst",
                                label: getI18nLocaleObject(namespaceList.dynamicResort, "availableResortsFirst"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) =>
                                    options.displayType === FacetDisplayType.POPUP ||
                                    (options.displayType === FacetDisplayType.MODAL_POPUP && options.greyOutUnavailableResorts) ||
                                    options.displayType === FacetDisplayType.GRID,
                            },
                            {
                                variable: "sortResortsByPriority",
                                label: getI18nLocaleObject(namespaceList.dynamicResort, "sortResortsByPriority"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) =>
                                    options.displayType === FacetDisplayType.POPUP || options.displayType === FacetDisplayType.MODAL_POPUP || options.displayType === FacetDisplayType.GRID,
                            },
                            {
                                variable: "useSingleResortSelection",
                                label: getI18nLocaleObject(namespaceList.dynamicResort, "useSingleResortSelection"),
                                type: "checkbox",
                            },
                            ...getWidgetTitleOptions<WidgetOptions>(),
                            ...getWidgetLabelOptions<WidgetOptions>(),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetAgenda, "styling"),
                    properties: [
                        [
                            multiSelectStylePicker("styleIds", TARGETS),
                            {
                                variable: "highlightInput",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "highlightInput"),
                                type: "checkbox",
                                default: false,
                                visible: (options: WidgetOptions) => options.displayType === FacetDisplayType.POPUP,
                            },
                            {
                                variable: "highlightColor",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "highlightColor"),
                                type: "dual-color",
                                default: "theme-ctaColor",
                                visible: (options: WidgetOptions) => options.displayType === FacetDisplayType.POPUP && options.highlightInput,
                            },
                            {
                                variable: "displayBtn",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "displayBtn"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.displayType === FacetDisplayType.POPUP || options.displayType === FacetDisplayType.MODAL_POPUP,
                            },
                            {
                                variable: "showApplyClear",
                                label: getI18nLocaleObject(namespaceList.admin, "showApplyClear"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.displayType === FacetDisplayType.POPUP || options.displayType === FacetDisplayType.MODAL_POPUP,
                            },
                            {
                                variable: "closeIcon",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "closeIcon"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.displayType === "displayPopup",
                            },
                            ...getModalLabelOptions(),
                            {
                                variable: "addIcon",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "addIcon"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.displayType === FacetDisplayType.POPUP || options.displayType === FacetDisplayType.MODAL_POPUP,
                            },
                            {
                                variable: "iconColor",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "iconColor"),
                                type: "dual-color",
                                default: "default",
                                visible: (options: WidgetOptions) => isModalPopup(options.displayType) && options.addIcon,
                            },
                            {
                                variable: "iconOutside",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "iconOutside"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => isModalPopup(options.displayType) && options.addIcon,
                            },
                            {
                                variable: "iconRight",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "iconRight"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => isModalPopup(options.displayType) && options.addIcon,
                            },
                            {
                                variable: "showArrow",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "showArrow"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.displayType === FacetDisplayType.POPUP || options.displayType === FacetDisplayType.MODAL_POPUP,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "titleParagraph"),
                                type: "paragraph",
                                visible: (options: WidgetOptions) => !!options.enableWidgetTitle,
                            },
                            ...titleStylingOptions<WidgetOptions>({ titleFontColorVariable: "textTitleColor" }),
                            ...labelStylingOptions<WidgetOptions>({ LabelFontColorVariable: "textLabelColor" }),
                        ],
                    ],
                },
            ],
        },
    ],
};

export const resortWidget: PageWidgetSpec<WidgetOptions> = {
    id: "resortWidget",
    type: "page",
    widgetGroup: WidgetGroup.DYNAMIC,
    name: getI18nLocaleObject(namespaceList.dynamicResort, "resort"),
    description: getI18nLocaleObject(namespaceList.dynamicResort, "resortDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        addIcon: false,
        arrowColor: "default",
        availableResortsFirst: false,
        displayBtn: false,
        displayType: FacetDisplayType.POPUP,
        greyOutUnavailableResorts: false,
        highlightColor: "theme-ctaColor",
        highlightInput: false,
        iconColor: "default",
        iconOutside: false,
        iconRight: false,
        numberOfColumnsInGridView: 1,
        showApplyClear: false,
        showArrow: false,
        showResortPlaceName: false,
        sortResortsByPriority: false,
        useSingleResortSelection: false,
        styleIds: [],
    }),
    async render(widget, context, sitemapWidgetOptions, resultOptions, dynamicContainerOptions) {
        if (isServerSide()) {
            await ResortFacetWidget.warmupCache({
                options: widget.options,
                context,
                availabilityIsFetching: false,
                availableResortIds: [],
                dynamicFilter: context.reduxStore.store.getState().dynamicFilter,
                dispatchAction: context.reduxStore.store.dispatch,
                availabilityState: context.reduxStore.store.getState().availabilityState,
            });
        }
        const { styleIds } = widget.options;
        const className = findMultiSelectStyleClassNames(context.theme, TARGETS, styleIds);
        return <DynamicResortFacet dynamicContainerOptions={dynamicContainerOptions} options={widget.options} context={context} className={className} />;
    },
    async initDefaultFilter(widget, context, dispatcher): Promise<void> {
        await ResortFacetWidget.initDefaultFilter({
            options: widget.options,
            context,
            dynamicFilter: context.reduxStore.store.getState().dynamicFilter,
            dispatchAction: dispatcher,
        } as any);
    },
};
